import React from 'react'
// import Image from 'next/image'
import styles from "../../src/ContHead.module.css"

function ContHead() {
  return (
    <div className={styles.container}>
        <div className={styles.ImgHead}>
             <img src="/img/cont.jpg" alt=""/>
        </div>
    </div>
  )
}

export default ContHead

import React from 'react'
import "../../ind.css";

function Faq() {
  return (
<div> 
    
        <div className='-mt-4'>
            <img src="/img/faq.jpg" alt=""/>
        </div>
        
        <div className="flex flex-col lg:px-40">
            <div className="bg-green-700 px-5 py-2 flex flex-col">
                <p className="text-white flex uppercase font-bold  text-center lg:justify-start">
                    I am new to Vehicle Inspection. How does this work?
                </p>
            </div> 
            
            <div className=" bg-gray-300 px-5 py-2 flex flex-col  border-2 border-green-700">
                <p className=" text-justify">
                    The Lagos State government is determined to ensure that accidents on the roads are reduced to the barest minimum hence, 
                    it is expected that road users subject their vehicles for inspection.
                    Failure to do so is a punishable offence under the law.
                </p>
            </div> 

            <div className="bg-green-700 px-5 py-2 flex flex-col">
                <p className="text-white flex uppercase font-bold text-center lg:justify-start">What happens during the Vehicle Inspection process?</p>
            </div> 
            <div className=" bg-gray-300 px-5 py-1 flex flex-col  border-2 border-green-700">
                <p className=" text-justify">
                As soon as you bring in your vehicle for inspection, a Marshal welcomes you at a dedicated holding area for your vehicle.
                He will collect your particulars (Vehicle license and previous road worthiness certificate) 
                and hand you over to a front desk officer who will authenticate your particulars.</p>

                <p>Once your records are verified, you will be required to pay the appropriate fees and be directed to a waiting room
                where you can watch the entire vehicle inspection process.It takes about 15- 20 minutes to get your vehicle inspected.</p>    
            </div>

            <div className="bg-green-700 px-5 py-2 flex flex-col">
                <p className="text-white flex uppercase font-bold text-center lg:justify-start ">Is the test fee the same for all vehicles?</p>
            </div> 
            <div className=" bg-gray-300 px-5 py-1 flex flex-col  border-2 border-green-700">
                <p className=" text-justify">
                LACVIS caters to fleet operators of both Heavy Duty Vehicles and Light 
                Duty Vehicles and domestic vehicle owners. Hence the fees vary depending 
                on the category of vehicle and its engine capacity.</p>    
            </div>
            

            <div className="bg-green-700 px-5 py-2 flex flex-col">
                <p className="text-white flex uppercase font-bold text-center lg:text-left ">How affordable is the test fee?</p>
            </div> 
            <div className=" bg-gray-300 px-5 py-1 flex flex-col  border-2 border-green-700">
                <p className=" text-justify">
                Quite affordable. Depending on the vehicle type and its engine capacity, considerations have been made 
                to ensure that the value you get for the vehicle inspection is commensurate with the test fee.
                However, all payments are one off and could include both for vehicle inspection and road worthiness fee.</p>    
            </div>


            <div className="bg-green-700 px-5 py-2 flex flex-col">
                <p className="text-white flex uppercase font-bold text-center lg:text-left ">What happens after the vehicle inspection?</p>
            </div> 
            <div className=" bg-gray-300 px-5 py-1 flex flex-col  border-2 border-green-700">
                <p className=" text-justify">
                The computerised test result is analysed by a certification officer. If your vehicle passes the test, 
                you get a Vehicle Inspection Report and a Road Worthiness Certificate from a resident vehicle inspection officer.

                If however, the vehicle fails the inspection, the certification officer will tell you the defect(s) found and 
                you will be advised to fix the defects and return for a retest before 30 days.</p>    
            </div>


            <div className="bg-green-700 px-5 py-2 flex flex-col">
                <p className="text-white flex uppercase font-bold text-center lg:text-left ">Do I have to pay for another test fee after I fix the defect(s)?</p>
            </div> 
            <div className=" bg-gray-300 px-5 py-1 flex flex-col  border-2 border-green-700">
                <p className=" text-justify">
                No. After fixing the defects, you can come for a free test during the 30 days validity period. However, 
                if you bring in the vehicle after the 30 days validity period, you will have to pay again to have your vehicle inspected.</p>    
            </div>


            <div className="bg-green-700 px-5 py-2 flex flex-col">
                <p className="text-white flex uppercase font-bold text-center lg:text-left ">Can I analyse the computer result?</p>
            </div> 
            <div className=" bg-gray-300 px-5 py-1 flex flex-col  border-2 border-green-700">
                <p className=" text-justify">
                Yes you can. The report is easy to read and understand and you’ll find answers to questions you may want to ask. However,
                our certification officer will still guide you through the report to clarify and answer further questions you may want to ask.</p>    
            </div>


            <div className="bg-green-700 px-5 py-2 flex flex-col">
                <p className="text-white flex uppercase font-bold text-center lg:text-left ">How secured is my vehicle during the inspection process?</p>
            </div> 
            <div className=" bg-gray-300 px-5 py-1 flex flex-col  border-2 border-green-700">
                <p className=" text-justify">
                When you bring in your vehicle for inspection, it will be handed over to one of our highly dedicated inspection officers. 
                They are trained to handle all types of vehicles and to ensure that your personal belongings are secured all through the process.</p>    
            </div>


            <div className="bg-green-700 px-5 py-2 flex flex-col">
                <p className="text-white flex uppercase font-bold text-center lg:text-left ">Can I bring my vehicle for inspection even if my road worthiness certificate hasn’t expired?</p>
            </div> 
            <div className=" bg-gray-300 px-5 py-1 flex flex-col  mb-2 border-2 border-green-700">
                <p className=" text-justify">
                Yes you can. To ensure safety of your vehicle at all time, you can run an independent check at any of our centres.</p>    
            </div>


        </div>

            
       



        

        {/* <div className="w-full bg-red-400 flex flex-col sm:flex-row ">
            <p className="h-full bg-gray-500 px-10 py-10 m-10 text-justify" >
                Lorem Ipsum is simply dummy text of the printing 
                and typesetting industry. Lorem Ipsum has been the industrys
                standard dummy <span className="uppercase">text ever since the 1500s</span>, when an unknown 
                printer took a galley of type and scrambled it to make a 
                type specimen book. It has survived not only five centuries, 
                but also the leap into electronic typesetting, remaining 
                essentially unchanged. It was popularised in the 1960s with 
                the release of Letraset sheets containing Lorem Ipsum passages, 
                and more recently with desktop publishing software like Aldus 
                PageMaker including versions of Lorem Ipsum.
            </p>

            <p className=" bg-gray-500 hover:bg-red-900 px-10 py-10 m-10 text-justify">
                Lorem Ipsum is simply dummy text of the printing 
                and typesetting industry. Lorem Ipsum has been the industrys
                standard dummy <span className="uppercase">text ever since the 1500s</span>, when an unknown 
                printer took a galley of type and scrambled it to make a 
                type specimen book. It has survived not only five centuries, 
                but also the leap into electronic typesetting, remaining 
                essentially unchanged. It was popularised in the 1960s with 
                the release of Letraset sheets containing Lorem Ipsum passages, 
                and more recently with desktop publishing software like Aldus 
                PageMaker including versions of Lorem Ipsum.
            </p>
        </div>    
   */}

    </div>


  )
}
export default Faq

// import { StylesContext } from '@material-ui/styles'
import React from "react";
import styles from "../../src/ContAdd.module.css";

function ContAdd() {
  return (
    <div className="bg-gray-200">
      <div className={styles.Add}>
        <div className={styles.Headd}>HEAD OFFICE</div>
        <div className={styles.Ad}>
          16, Muyibat Oyefusi Street, Omole Lagos.
        </div>
        <div className={styles.Tel}>Tel: 08180003426, 08186797196</div>
        <div className={styles.Tick}>E-Billing/Fine Ticket: 08033208549</div>
        <div className={styles.Mailet}>Email: info@lacvis.com</div>

        <div className={styles.TestC}>VEHICLE INSPECTION CENTRE </div>
      </div>

      <div className="bg-red-800"></div>

      {/* ############################################ CONTAINER 1 ############################################## */}

      <div className={styles.Container1}>
        <div className={styles.ContAddsub11}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}> AGBOWA</div>
            <div className={styles.Head2}>
              Behind Agbowa LCDA VIO new building, Ota Road Lagos
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>AGRIC ISHAWO</div>
            <div className={styles.Head2}>
              VIS Office, Beside Rehabilitation Vocational Centre, Oremeji
              Taylor Close, Off Isawo/Owutu Road, Ikorodu West Lagos
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>AJAH</div>
            <div className={styles.Head2}>
              VIS Zone Office, Ilaje Road, Ajah Eti Osa. Lagos
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>ALAUSA</div>
            <div className={styles.Head2}>
              Assbifi Rd, beside Passport Office, Alausa CBD, Ikeja Lagos
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>ANTHONY</div>
            <div className={styles.Head2}>
              VIS Testing Ground, Ikorodu road, by Anthony Bus Stop Anthony,
              Lagos
            </div>
          </div>
        </div>
      </div>

      {/* ############################################ CONTAINER 2 ############################################## */}

      <div className={styles.Container2}>
        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>AYOBO</div>
            <div className={styles.Head2}>
              VIS Office, Falola street, Majiyagbe layout, Church busstop,
              Ipaja, Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>BADAGRY</div>
            <div className={styles.Head2}>
              VIS Office, PWD Yard, Hospital Road, Badagry town, Badagry Lagos
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>BOLADE OSHODI</div>
            <div className={styles.Head2}>
              Office, Safety Arena, Bolade Oshodi behind Bolade Market Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>CELE</div>
            <div className={styles.Head2}>
              Itire-Ikate LCDA Office Annex, by Cele Bus Stop Lagos
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>EJIGBO</div>
            <div className={styles.Head2}>
              VIS Ejigbo Zone, Opposite UBA, Ejigbo, Lagos.
            </div>
          </div>
        </div>
      </div>

      {/* ############################################ CONTAINER 2 ############################################## */}

      <div className={styles.Container2}>
        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>EPE</div>
            <div className={styles.Head2}>
              VIS Work Yard, After T-Junction, Opposite Epe Resort& Spa, Epe
              Itoki Express Way, Epe Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>GBAGADA</div>
            <div className={styles.Head2}>
              VIS Office, NCI Gbagada Lagos, Beside floor mill, Gbagada Lagos
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>IBEJU-LEKKI</div>
            <div className={styles.Head2}>
              Behind Ibeju Lekki LG Secretariate, Igango Olopa, Lekki Epe
              Express Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>IKOTUN IGANDO</div>
            <div className={styles.Head2}>
              Ikotun-Igando Road Alimosho LGA, Igando, Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>LAGOS ISLAND</div>
            <div className={styles.Head2}>
              VIS Work Yard, Olowu Street, Lagos Island
            </div>
          </div>
        </div>
      </div>

      {/* ############################################ CONTAINER 2 ############################################## */}

      <div className={styles.Container2}>
        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>LASU</div>
            <div className={styles.Head2}>
              Iyana Oba Bus Stop Ojo Campus. Badagry Express way, Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>LAKOWE</div>
            <div className={styles.Head2}>
              Lakowe Bus Stop, Ibeju, Lekki Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>ODOGUNYAN</div>
            <div className={styles.Head2}>
              VIS Office, Odogunyan by Olopomeji bus-stop, Ikorodu, Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>OJOTA</div>
            <div className={styles.Head2}>
              Mechanic Village Workshop, beside Olushosun Dumping Site Ojota
              bus/stop Lagos
            </div>
          </div>
        </div>
        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>OJODU</div>
            <div className={styles.Head2}>
              VIS Testing Ground, Ogunnusi Road, Ojodu, Lagos.
            </div>
          </div>
        </div>
      </div>

      {/* ############################################ CONTAINER 2 ############################################## */}

      <div className={styles.Container2}>
        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>OKO-OBA</div>
            <div className={styles.Head2}>
              VIS Office,1 Palm-View Estate, Suco Bus-stop, Opposite RRS
              Command, Oko-Oba,Agege, Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>OKO-AFO</div>
            <div className={styles.Head2}>
              VIS Office, LASTMA Bus-stop, Badagry express way, Oko-Afo Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>OWODE</div>
            <div className={styles.Head2}>
              Owode Onirin Bus Stop, Ikorodu Road, Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}> OKE-ODO</div>
            <div className={styles.Head2}>
              24/26 Old Otta road Ile Epo, Oke Odo Alimosho, Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>PWD</div>
            <div className={styles.Head2}>
              VIS Office, Work yard, Old secretariat, Ikeja, Lagos.
            </div>
          </div>
        </div>
      </div>

      {/* ############################################ CONTAINER 2 ############################################## */}

      <div className={styles.Container2}>
        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>UNILAG</div>
            <div className={styles.Head2}>Opposite UNILAG Mosque, Lagos.</div>
          </div>
        </div>

        {/* <div className={styles.ContAddsub1}>
                <div className={styles.ContAddhead}>
                    <div className={styles.Head1}>SURU ALABA</div>
                        <div className={styles.Head2}>                         
                              4, Asemota Street Alaba Ojo, Lagos.
                        </div>
                </div>
        </div> */}

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>SURULERE</div>
            <div className={styles.Head2}>
              4 Ishola Street, Off Randle Arena, Ojuelegba Surulere, Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}> YABA</div>
            <div className={styles.Head2}>
              8, Edmond Crescent Jibowu Yaba Lagos
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}> YABA FSTC</div>
            <div className={styles.Head2}>62 Modele Street, Yaba Lagos</div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>MS 1 ETI-OSA</div>
            <div className={styles.Head2}>
              Eti-Osa Testing Ground Mma Lucy Akpabio Str. Off Muri Okunola Str.
              Victoria Island, Lagos.
            </div>
          </div>
        </div>
      </div>


      {/* ############################################ CONTAINER 2 ############################################## */}

      <div className={styles.Container2}>
        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>MS 2 DALEKO</div>
            <div className={styles.Head2}>VIO Yard, Olu Aina Street Daleko Isolo. Lagos</div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>MS 3  BOURDILLON</div>
            <div className={styles.Head2}>
              NIPOST Falomo, Off Bourdillon Road. Ikoyi. Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>MS 4  LIASU</div>
            <div className={styles.Head2}>
              Lagos Misnistry Transport, VIS LIASU, Adebimpe Avenue, 
              Off Council Round About. Ikotun. Lagos.
            </div>
          </div>
        </div>

        <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}> MS 5 AA RESCUE LEKKI</div>
            <div className={styles.Head2}>13/15 Castle Rock Avenue, Jakande Round About. Lekki. Lagos.</div>
          </div>
        </div>

        {/* <div className={styles.ContAddsub1}>
          <div className={styles.ContAddhead}>
            <div className={styles.Head1}>MS 1 ETI-OSA</div>
            <div className={styles.Head2}>
              Eti-Osa Testing Ground Mma Lucy Akpabio Str. Off Muri Okunola Str.
              Victoria Island, Lagos.
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ContAdd;

import React from 'react'
import styles from '../../src/MoreServ1.module.css'
import Button from '@material-ui/core/Button'

function MoreServ1() {
  return (
    <div className={styles.Container}>
        <div className={styles.Container1}>
            <div className={styles.serv3sub1}>
                <div className={styles.Serv2sub1head}>
                    <div className={styles.Head1}>VOLUNTEERING INSPECTION</div>
                        <div className={styles.Head2}>
                           These are inspections where vehicle owners opt for willingly and not been coerce to undergo them.
                        </div>
                </div>
            </div>
        
    

            <div className={styles.serv3sub1}>
                <div className={styles.Serv2sub1head}>
                    <div className={styles.Head1}>RE-INSPECTION</div>
                        <div className={styles.Head2}>
                            If your vehicle fails to meet the requirements and standards of an Initial, Special or Routine 
                            Inspection, you will be required to rectify the problem(s) and come back for a re-inspection. 
                            We will re-inspect the previously failed item(s).
                        </div>
                </div>
            </div>


            <div className={styles.serv3sub1}>
                <div className={styles.Serv2sub1head}>
                    <div className={styles.Head1}>VEHICLE AUDITING</div>
                        <div className={styles.Head2}>                 
                            It is an inspection mostly done by corporate bodies for the purpose of thorough audit to give 
                            detailed report on the status of their vehicles.
                        </div>
                </div>
            </div> 
        </div>
    
        <div className={styles.But}>
            <div className={styles.Head1}>STATUTORY ROAD WORTHINESS INSPECTION</div>
                It is a mandatory requirement for all private and commercial vehicles to undergo a 
                Routine Inspection at LACVIS, in order to certify compliance to regulations. During this inspection, 
                we will check the legality and roadworthiness of the vehicle. The routine inspection duration for Private 
                vehicle is once every year, while commercial vehicle is every six months.
        </div>
        


        <div className={styles.MoreServ}>            
        <a href="/services" passHref>
          <Button
            style={{
              borderRadius: 15,
              backgroundColor: "#228B22",
              padding: "10px 50px",
              fontSize: "15px",
              fontWeight: "bold",
              color: "white",
            }}
              variant="contained"

             > back
          </Button>
        </a>
      </div>
        

          <div className={styles.Space}>
           
          </div> 
          
    </div>
  )
}

export default MoreServ1

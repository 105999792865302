import Carousel from 'react-bootstrap/Carousel';
import "../ind.css";


function UncontrolledExample() {
  return (
    <Carousel className='mb-20'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/img/cust1.jpg"
          alt="First slide"

        />
      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/img/cust2.jpg"
          alt="Second slide"
        />
      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/img/cust3.jpg"
          alt="Third slide"
        />
      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/img/cust4.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

   

        
    </Carousel>
  );
}

export default UncontrolledExample;
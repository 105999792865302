import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./Components/Navbar/Navbar";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import Faq from "./pages/Faq/Faq";
import Contact from "./pages/Contact/Contact";
import Footer from "./Components/Footer";
import MoreServices from "./MoreServices";
import Policy from "../src/Components/Policy"
import Condition from "../src/Components/Condition"
import Career from "../src/Components/Career"


const App = () => {

  
 
  return (
    <Router>
      <Navbar/>
     <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="faq" element={<Faq />} /> 
        <Route path="contact" element={<Contact />} /> 
        <Route path="moreservices" element={<MoreServices />} />   
        <Route path="policy" element={<Policy />} /> 
        <Route path="condition" element={<Condition />} /> 
        <Route path="career" element={<Career />} /> 
      </Routes>
      <Footer/>
    </Router>

  );
}

export default App;

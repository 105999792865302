import React from 'react';
// import Head from 'next/head';
// import Image from "next/image";

function Policy() {
  return (
    <div className='bg-gray-300 -mt-5'>

    
        
            <img src="/img/privacy.jpg" alt=""/>
        
        
        <div className='bg-gray-300'>
            <div className='lg:px-40 pt-5 text-xl lg:text-3xl text-green-900 font-bold flex flex-col text-center'>
                WE RESPECT YOUR PRIVACY. OUR GUIDELINES FOR PROTECTING THE INFORMATION YOU PROVIDE 
                US DURING YOUR VISIT TO OUR WEBSITE ARE EXPLAINED BELOW
            </div>
        </div>

      <div className='lg:px-40 text-justify pb-5 flex flex-col'>
            <p className='text-xl font-bold pt-11 flex flex-col text-center lg:text-left '>COLLECTION AND USE OF INFORMATION</p>

              <p className='px-5 lg:px-0 text-lg lg:text-base'>
                We collect information volunteered by the visitor in registering to our newsletter or by submitting 
                via the online enquiry form. We use this information to administer our business activities, improve 
                our website, provide customer service, and to make available other products and services to our customers
                and prospective customers. We do not disclose your personal identity information to unaffiliated third 
                parties. We do not sell, rent, or share any customer information that we collect on our website.
              </p>
            
             <p className='pt-5 lg:px-0 text-lg px-5 lg:text-base'>If you supply us with your e-mail address and phone numbers, you may receive periodic emails or SMS from
             us with information about new products and services or upcoming events. If you do not wish to receive 
             such mailings or calls, you may “opt out” by clicking on the “Unsubscribe” link in the email or send us 
             an email at : customercare@lacvis.com.ng</p>

             <p className='pt-5 lg:px-0 text-lg px-5 lg:text-base'>In addition to the information you volunteer, our web server automatically collects such information 
            as the domain name of the site providing you with Internet access, the Internet protocol (IP) address 
            used to connect your computer to the Internet, your browser type and version, operating system and platform, 
            the average time spent on our site, pages viewed, information searched for, access times and other relevant statistics. 
            We use this information in order to ensure that unauthorized users do not access the information on our website, 
            and in the aggregate to measure the use of our site and to administer and improve our site.</p>

            
            <p className='text-xl font-bold pt-11 flex flex-col text-center lg:text-left '>COOKIES</p>

            <p className='lg:px-0 text-lg px-5 lg:text-base'>
              Cookies store data on a user’s hard drive and it contains information about the user. 
              We reserve the right to use cookies on our website. We use cookies to review and track 
              user interests so that we can improve the quality of our website. If your browser rejects 
              a cookie, our website can still be used.
            </p>


            <p className='text-xl font-bold pt-11 flex flex-col text-center lg:text-left'>CHANGES TO THE PRIVACY POLICY</p>

            <p className='lg:px-0 text-lg px-5 lg:text-base'>By using our website, you consent to our collection and use of your information as described
            in this Privacy Policy. We reserve the right to modify this Privacy Policy and related business
            practices at any time by posting updated texts on this website. Please check this page periodically for updates.

            Thank you for visiting our website, and for taking the time to read this Privacy Policy.

            In the event that you have questions pertaining to this Privacy Policy or the information you have 
            provided, please contact : customercare@lacvis.com.ng
            </p>
      </div>            
        
      
    </div>
  )
}

export default Policy

import React from 'react';
import MoreServ from './Components/MoreServ'
import MoreServ1 from './Components/MoreServ1'


function MoreServices() {
  return (
    <div>
 
    <MoreServ/>
    <MoreServ1/>
    </div>
  )
}

export default MoreServices

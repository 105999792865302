import React from 'react'
import ContHead from '../../Components/ContHead';
import ContAdd from '../../Components/ContAdd';

function Contact() {
  return (
    <div className='-mt-5'>
      <ContHead/>
      <ContAdd/>
    </div>
  )
}

export default Contact

import React from 'react'
import styles from '../../src/MoreServ.module.css'
// import Image from "next/image";
// import Link from 'next/link';
// import Button from '@material-ui/core/Button'

function MoreServ() {
  return (
    <div className={styles.Container}>
        <div className={styles.Cont1}>

 <h2 className={styles.Myh2}>Service Range</h2> 

In an effort to provide comprehensive and standardized service in LAGOS STATE, Our inspection facilities are located
at strategic areas in Lagos to meet our customer’s requirement and serve them better by offering world class services.
Avoid waiting in line and arrange for us to come to you! Our Mobile Inspection, offering a range of inspections can 
be done while you wait at a location of your choice. LACVIS checks the design drawings of private and new. commercial 
vehicles to verify that they meet the requirements set out in the legislation in particular, with respect to ‘Construction 
and Use’, For vehicle that are reconstructed or adjusted, we control and inspect the adjustment to ensure that it comply with 
the manufacturer’s advice and also conform to regulation.

At the moment, there are more than thirty Inspection Centers span in strategic areas in Lagos.
These centers are equipped with multiple testing lanes and can carry out any type of inspection. 
It is important to us that we provide inspection service to all, including our customers living 
in remote areas of the state. Thus, we will visit these remote places with our mobile testing units 
for regular inspection of our customers’ vehicles.

        </div> 

      <div className={styles.Container1}>
          <div className={styles.serv3sub1}>

                <div className={styles.Serv3sub1head}>
                    <div className={styles.Head1}>STATUTORY ROAD WORTHINESS INSPECTION</div>
                        <div className={styles.Head2}>
                        These are inspections done for vehicles about to be registered and 
                        for road worthiness purpose. It is mandatory because the law says so.
                        </div>
               </div>
           </div>

          <div className={styles.serv3sub1}>
        

                <div className={styles.Serv3sub1head}>
                    <div className={styles.Head1}>GOVERNMENT VEHICLE INSPECTION</div>
                        <div className={styles.Head2}>
                        All government vehicles are encouraged to undergo
                        inspections to ascertain the roadworthiness of the vehicle and that it is in 
                        accordance with the regulations.
                        </div>
                </div>
          </div>

          <div className={styles.serv3sub1}>
                <div className={styles.Serv3sub1head}>
                    <div className={styles.Head1}>COMMERCIAL VEHICLE INSPECTION</div>
                        <div className={styles.Head2}>Before a new commercial vehicle can be registered, 
                        LACVIS must verify that it complies with the standards set out by the authorities.
                        </div>
                </div>
          </div> 
      </div>
      </div>
  )
}

export default MoreServ

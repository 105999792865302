import React from 'react'
import "../ind.css";

function Hew() {
  return (
    <div className='bg-green-500 -mt-5'>
      
    </div>
  )
}

export default Hew

import React from 'react';
import Hew from '../../Components/Hew';
import "../../ind.css";
import Caro from "../../Components/Carousel";
import Disply from '../../Components/Disply';
import View from '../../Components/View';
import Viewerr from '../../Components/Viewerr';
import Viewer1 from '../../Components/Viewer1';
import Secview from '../../Components/Secview';
import Over from '../../Components/Over';
// import Footer from '../../Components/Footer';




function Home() {
  return (
    <div>
      <Hew/>
      <Caro/>
      <Disply/>
      <View/>
      <Viewerr/>
      <Viewer1 />
      <Secview />
      <Over/>
      {/* <Footer/> */}
      {/* <Viewer2 /> */}

    </div>
  )
}

export default Home

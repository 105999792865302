import React from 'react'
import styles from "../../src/Ourpip.module.css"
// import Image from "next/image";

function Ourpips1() {
  return (
    <div className={styles.Container}>
        <div className={styles.Wrap}>
            <img className='crop' src='/img/MD.jpg' width={200} height={195} alt="" />   
            <style jsx global>{`
                .crop {
                border-radius: 40% !important;
                object-fit: cover;
            }
    
            `}</style>
            <div className={styles.Vtitle}> MD CEO </div>
            <div className={styles.Vtitlee}> PRINCE SEGUN OBAYENDO </div>
        </div>

        {/* <div className={styles.Wrap}>
            <img className='crop' src='/img/GM.jpg' width={200} height={195} alt="" />   
            <style jsx global>{`
                .crop {
                border-radius: 50%;
                object-fit: cover;
            }
    
            `}</style>
           <div className={styles.Vtitle}> GM </div>
            <div className={styles.Vtitlee}> SEGUN OGUNNAIKE </div>
        </div> */}

        <div className={styles.Wrap}>
            <img className='crop' src='/img/AUDIT.jpg' width={200} height={195} alt=""/>   
            <style jsx global>{`
                .crop {
                border-radius: 50%;
                object-fit: cover;
            }
    
            `}</style>
             <div className={styles.Vtitle}> BUSINESS DEVELOPMENT </div>
            <div className={styles.Vtitlee}> VICTOR ERHABOR </div>
        </div>

        <div className={styles.Wrap}>
            <img className='crop' src='/img/HFA.jpg' width={200} height={195} alt=""/>   
            <style jsx global>{`
                .crop {
                border-radius: 50%;
                object-fit: cover;
            }
    
            `}</style>
              <div className={styles.Vtitle}> CAO </div>
            <div className={styles.Vtitlee}> PRECIOUS ADEMOROTI </div>
        </div>

        <div className={styles.Wrap}>
            <img className='crop' src='/img/HOP.jpg' width={200} height={195} alt=""/>   
            <style jsx global>{`
                .crop {
                border-radius: 25%;
                object-fit: cover;
            }
    
            `}</style>
            <div className={styles.Vtitle}> COO </div>
            <div className={styles.Vtitlee}> LARBI EDWARD </div>
        </div>
 

        <div className={styles.Wrap}>
            <img className='crop' src='/img/MM.jpg' width={200} height={195} alt=""/>   
            <style jsx global>{`
                .crop {
                border-radius: 25%;
                object-fit: cover;
            }
    
            `}</style>
            <div className={styles.Vtitle}> MAINTENANCE </div>
            <div className={styles.Vtitlee}> ROCKSON GYEBI </div>
        </div>

        <div className={styles.Wrap}>
            <img className='crop' src='/img/PROJECT.jpg' width={200} height={195} alt=""/>   
            <style jsx global>{`
                .crop {
                border-radius: 25%;
                object-fit: cover;
            }
    
            `}</style>
            <div className={styles.Vtitle}> PROJECT</div>
            <div className={styles.Vtitlee}> ADEDEJI AYODEJI </div>
        </div>

        <div className={styles.Wrap}>
            <img className='crop' src='/img/HR.jpg' width={200} height={195} alt=""/>   
            <style jsx global>{`
                .crop {
                border-radius: 25%;
                object-fit: cover;
            }
    
            `}</style>
             <div className={styles.Vtitle}> HRM </div>
            <div className={styles.Vtitlee}> OMAMOKE ABELE </div>
        </div>

        <div className={styles.Wrap}>
            <img className='crop' src='/img/ADMIN.jpg' width={200} height={195} alt=""/>   
            <style jsx global>{`
                .crop {
                border-radius: 25%;
                object-fit: cover;
            }
    
            `}</style>
             <div className={styles.Vtitle}> ADMIN  </div>
            <div className={styles.Vtitlee}> MAUREEN MBATA </div>
        </div>

        <div className={styles.Wrap}>
            <img className='crop' src='/img/BDMR.jpg' width={200} height={195} alt=""/>   
            <style jsx global>{`
                .crop {
                border-radius: 25%;
                object-fit: cover;
            }
    
            `}</style>
              <div className={styles.Vtitle}> CORP. COMMUNICATION  </div>
            <div className={styles.Vtitlee}> ABIKE AWOJOBI </div>
        </div>
        
        <div className={styles.Wrap}>
            <img className='crop' src='/img/LEGAL.jpg' width={200} height={195} alt=""/>   
            <style jsx global>{`
                .crop {
                border-radius: 25%;
                object-fit: cover;
            }
    
            `}</style>
                 <div className={styles.Vtitle}> LEGAL  </div>
            <div className={styles.Vtitlee}> OLADELE CHARLES</div>
        </div>

    

        <div className={styles.Wrap}>
            <img className='crop' src='/img/IT.jpg' width={200} height={195} alt=""/>   
            <style jsx global>{`
                .crop {
                border-radius: 25%;
                object-fit: cover;
            }
    
            `}</style>
                 <div className={styles.Vtitle}> IT  </div>
            <div className={styles.Vtitlee}> EMMANUEL OGUNYEMI</div>
        </div>

        <div className={styles.Wrap}>
            <img className='crop' src='/img/PROC.jpg' width={200} height={195} alt=""/>   
            <style jsx global>{`
                .crop {
                border-radius: 25%;
                object-fit: cover;
            }
    
            `}</style>
                 <div className={styles.Vtitle}> PROCUREMENT  </div>
            <div className={styles.Vtitlee}> MORENIKE IKUOYEMI</div>
        </div>
    </div>
  )
}

export default Ourpips1
import React from 'react'
// import styles from "../styles/AbtMid.module.css"
// import Image from "next/image";


function AbtMid() {
  return (

    <div className='animate__bounceIn'>
        <div className='flex text-center justify-center lg:text-3xl font-bold py-8 text-green-900 lg:flex-row'>
            LACVIS… Safer Roads and Sustainable Environment
        </div>

        <div className='lg:px-24 lg:flex px-16'>
            <div className='lg:text-justify lg:px-2 lg:w-3/6'>
              LACVIS Nigeria limited was established in 2016, 
              to introduce vehicle testing technologies and undertake computerized 
              vehicle inspection services in Lagos state. The Lagos Computerized Vehicle Inspection Service, 
              LACVIS, is a special purpose vehicle, set up by the Lagos State government and other stakeholders 
              to partner with the state’s Vehicle Inspection Service to ensure the safety of the motoring public 
              Vehicles are tested for the purpose of ascertaining their worthiness on roads and for the issuance 
              of Road Worthiness Certificate. In pursuing this mandate.
              LACVIS was set up by a strong desire to provide lasting solution to the historical 
              antecedents of dangers posed by having vehicles that are not road worthy on Lagos 
              State roads. LACVIS is regulated by the Lagos State Ministry of Transport to provide 
              technical and operational infrastructure to inspect vehicles according to the statutory  
              
              
              
            </div>

            <div className='lg:text-justify lg:pl-4 lg:w-3/6'>
              provisions of the law. statutory  provisions of the law.  LACVIS work with major stakeholders namely: VIO, FRSC, Nigeria Police, 
              Road Traffic Unions, Electronics and Print Media, the press, and the General Public.
              Lagos Computerised Vehicle Inspection Service (LACVIS) is Nigeria’s 
              leading vehicle inspection specialist. Our inspections protect motorists and 
              increase safety for all road users. Today, LACVIS has 34 inspection centers 
              presently in Lagos, with the aim of building over 50 center across Lagos in 
              the next 3 years. We are set on a path of continued expansion and improvements 
              that will meet the needs of the state rapidly increasing motoring population. 
              We pride ourselves in inspecting vehicles to international standards, saving 
              lives and giving support to Lagos State Ministry of Transportation.
          </div>
        </div>

       

        <div className='bg-gray-200 mt-12 py-24 '>
            <div className='flex justify-center text-3xl font-bold text-green-700 px-24'>
              MISSION
            </div>
            <div className='flex justify-center italic font-semibold text-center px-8'>
              To  be the preferred Vehicle Inspection Operator of African origin with Global Acceptance.
            </div>

            <div className='flex justify-center text-3xl font-bold pt-12 text-green-700 px-24'>
              VISION
            </div>
            <div className='flex justify-center italic font-semibold text-center pb px-8'>
              To collaborate with the government in reducing road traffic crashes and fatality in the country<br/> 
              by guarantying sound road worthiness of vehicles. 

              To Partner with relevant agencies, stakeholders 
              and the<br/> general public in saving the 
              lives and properties of Nigerians through improved road safety administration.
            </div>
        </div>


          <div className='flex flex-col lg:px-24 px-16'>
            <div className='text-3xl font-bold py-8 text-green-700 flex lg:justify-start justify-center'>
                Our People
            </div>

            <div className=''>
                We employ highly trained-Mechanical Engineers, certified in vehicle inspection 
                with certificate in auto mobile engineering. These vehicle examiners have years
                of experience to conduct professional vehicle inspection. We trust in fostering
                an environment that promotes continuous learning and development as we strive to
                harness the capability and potential of our people.
            </div>
        </div>
        

    {/* // <div className={styles.container}>
    //    <div className={styles.head}> LACVIS… Safer Roads and Sustainable Environment </div>
    //     <div className={styles.wrap}>
    //       <div className={styles.wrap1}>
    //       LACVIS Nigeria limited was established in 2016, 
    //       to introduce vehicle testing technologies and undertake computerized 
    //       vehicle inspection services in Lagos state. The Lagos Computerized Vehicle Inspection Service, 
    //       LACVIS, is a special purpose vehicle, set up by the Lagos State government and other stakeholders 
    //       to partner with the state’s Vehicle Inspection Service to ensure the safety of the motoring public 
    //       Vehicles are tested for the purpose of ascertaining their worthiness on roads and for the issuance 
    //       of Road Worthiness Certificate. In pursuing this mandate.
    //       LACVIS was set up by a strong desire to provide lasting solution to the historical 
    //       antecedents of dangers posed by having vehicles that are not road worthy on Lagos 
    //       State roads. LACVIS is regulated by the Lagos State Ministry of Transport to provide 
    //       technical and operational infrastructure to inspect 
    //       </div>

    //       <div className={styles.wrap1}>
         
    //       vehicles according to the statutory  provisions of the law. LACVIS work with major stakeholders namely: VIO, FRSC, Nigeria Police, 
    //       Road Traffic Unions, Electronics and Print Media, the press, and the General Public.
    //       Lagos Computerised Vehicle Inspection Service (LACVIS) is Nigeria’s 
    //       leading vehicle inspection specialist. Our inspections protect motorists and 
    //       increase safety for all road users. Today, LACVIS has 31 inspection centers 
    //       presently in Lagos, with the aim of building over 50 center across Lagos in 
    //       the next 3 years. We are set on a path of continued expansion and improvements 
    //       that will meet the needs of the state rapidly increasing motoring population. 
    //       We pride ourselves in inspecting vehicles to international standards, saving 
    //       lives and giving support to Lagos State Ministry of Transportation.
    //       </div>
    //     </div>

    //     <div className={styles.misvis}>
    //         <div className={styles.misviscont}>
    //           <div className={styles.misvishd}>VISION</div>
    //           <div className={styles.misviswrp}>To  be the preferred Vehicle Inspection Operator of African origin with Global Acceptance.</div>
    //           <br/><br/>
    //           <div className={styles.misvishd}>MISSION</div>
    //           <div className={styles.misviswrp}>To collaborate with the government in reducing road traffic crashes and fatality in the country<br/> by guarantying sound road worthiness of vehicles. 
    //           To Partner with relevant agencies, stakeholders <br/>and the general public in saving the 
    //           lives and properties of Nigerians through improved road safety administration.</div>
    //         </div>
          
    //     </div>  */}
     </div>
  )
}

export default AbtMid
import React from 'react'
import styles from '../../src/Serv3Sec.module.css'
import { Link } from 'react-router-dom'
// import Image from "next/image";
// import Link from 'next/link';
import Button from '@material-ui/core/Button'



function Serv3Sec() {
  return (
    <div className={styles.Container}>
      <div className={styles.Container1}>
          <div className={styles.serv3sub1}>
              <img className='crop1' src='/img/lamp.jpg' width={406} height={384} alt="money" />   
              <style jsx global>{`
              .crop1 {
              //   border-radius: 50%;
                object-fit: cover;
                border-radius: 0 0 0 0; 
                width:100%;
              
               `}</style>


                <div className={styles.Serv3sub1head}>
                    <div className={styles.Head1}>HEADLAMPS INSPECTION</div>
                        <div className={styles.Head2}>Headlamps deterioration which affects its aim 
                        and intensity could cause accidents on the roads. Be a safer driver by knowing
                         the acceptable standard of the intensity and headlamp aim of your vehicle.
                        </div>
               </div>
           </div>

          <div className={styles.serv3sub1}>
            <img className='crop1' src='/img/susp.jpg' width={4066} height={384} alt="money" />   
            <style jsx global>{`
            .crop1 {
            //   border-radius: 50%;
              object-fit: cover;   
            //   border-radius: 0px 0px 0 0;     
            }  
               `}</style>

                <div className={styles.Serv3sub1head}>
                    <div className={styles.Head1}>SUSPENSION INSPECTION</div>
                        <div className={styles.Head2}>The suspension inspection checks the stability 
                        of your vehicle and defects that could lead to a stressful journey for you 
                        and other passengers.<p>`</p>
                        </div>
                </div>
          </div>

          <div className={styles.serv3sub1}>
            <img className='crop1' src='/img/align.jpg' width={406} height={384} alt="money" />   
            <style jsx global>{`
            .crop1 {
            //   border-radius: 50%;
              object-fit: cover;    
              width:100%;   
            }  
               `}</style>

                <div className={styles.Serv3sub1head}>
                    <div className={styles.Head1}>ALIGNMENT INSPECTION</div>
                        <div className={styles.Head2}>Improperly alignment within allowable limits waste fuel,
                         takes its toll on tires and result in steering difficulties which can prove 
                         deadly on the roads.<p>.</p>
                        </div>
                </div>
          </div> 
      </div>

      <div className={styles.MoreServ}>            
      <Link to={"/moreservices"}>    
         <Button
            style={{
              borderRadius: 15,
              backgroundColor: "#228B22",
              padding: "10px 50px",
              fontSize: "15px",
              fontWeight: "bold",
              color: "white",
            }}
              variant="contained"

             > More...
          </Button>
       
          </Link>

      </div>
        

          <div className={styles.Space}>
           
          </div> 


    </div>

    
   


  )
}

export default Serv3Sec

import React from "react";
import styled from 'styled-components';
// import { Link } from 'react-router-dom'


const Footer = () => {
return (
	<Box>
 
	<Container>
		<br></br>
		<Row>
		    <Column>

		        <Heading1>
		           <img src= "/img/laclogo.png" alt=''/><br></br>
				    <h6 style={{ color: "#fff",
				    fontStyle: "italic",
				    fontSize: "12px",
				   }}>Lagos Computerized Vehicle Inspection Service</h6>

				   <h6 className="text-red-500">LACVIS.. Safer Roads and Sustainable Environment</h6>
				   <h6 className="text-gray-600 pt-4">LACVIS NIG. LTD. © 2024 / ALL RIGHTS RESERVED</h6>
	            </Heading1>
				
			
		    </Column>

		    <Column>
			<Heading2>
		        <Heading1>EXTRA LINK</Heading1>
				    {/* <h6 style={{ color: "#fff",
				    fontStyle: "italic",
				    // textAlign: "center",
				    fontSize: "12px",
				    // textAlign: "center",
					lineHeight:"25px",
				   }}> Staff Email</h6> */}
		   <a href="https://lacvis.com/admin/home.php#"> <h6 className="text-white italic text-xs">Portal</h6></a>
				   <h6 className="text-white italic text-xs">News</h6>
				   <h6 className="text-white italic text-xs">Magazines</h6>
			<a href="/career"><h6 className="text-white italic text-xs">Career</h6></a>
			<a href="/policy"><h6 className="text-white italic text-xs">Privacy Policy</h6></a>
			<a href="/condition"><h6 className="text-white italic text-xs">Terms & Condition</h6></a>
			</Heading2>
			</Column>

			<Column>
			<Heading3>
		        <Heading2>OPENING HOURS</Heading2>
				    <h6 style={{ color: "#fff",
				    fontStyle: "italic",
				    // textAlign: "center",
				    fontSize: "12px",
					lineHeight:"25px",
					
				   }}> Mon - Fri [8am - 5pm]</h6>
				</Heading3>
			
		    </Column>


		
		<Column>
<Heading4>
			<Heading4>SOCIAL MEDIA</Heading4>
			<FooterLink href="https://www.facebook.com/OfficialLACVIS/">
			<i className="fab fa-facebook-f">
				<span style={{ marginLeft: "10px" }}>
				Facebook
				</span>
			</i>
			</FooterLink>
			<FooterLink href="https://www.instagram.com/officiallacvis/?utm_source=ig_profile_share&igshid=pm7q81bq3mcy">
			<i className="fab fa-instagram">
				<span style={{ marginLeft: "10px" }}>
				Instagram
				</span>
			</i>
			</FooterLink>
			<FooterLink href="https://twitter.com/officialLacvis">
			<i className="fab fa-twitter">
				<span style={{ marginLeft: "10px" }}>
				Twitter
				</span>
			</i>
			</FooterLink>
			<FooterLink href="https://www.youtube.com/channel/UC8q1tP5LKgznumzTDszZnvw">
			<i className="fab fa-youtube">
				<span style={{ marginLeft: "10px" }}>
				Youtube
				</span>
			</i>
			</FooterLink>
			</Heading4>
		</Column>
	
		</Row>
		Powered by: ToshDev
	</Container>
	</Box>
);
};
export default Footer;


const Box = styled.div`
padding-top:2%;
background: #000;
bottom: 0;
width: 100%;


@media (max-width: 1000px) {
	padding: 70px 30px;
	margin-top: 0px;
	
}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1600px;
	margin: 0 auto;

`;

export const Column = styled.div`
display: flex;
flex-direction: column;
text-align: left;
margin-left: 130px;
// justify-content:center;

@media (max-width: 1000px) {
	flex-direction: none;
	margin-left: 130px;			
}

`;


export const Row = styled.div`
display: flex;
grid-gap:50px;

@media (max-width: 1000px) {
	display:grid;
	grid-gap:50px;
}
`;

export const FooterLink = styled.a`
color: #fff;
margin-bottom: 10px;
font-size: 12px;
text-decoration: none;

&:hover {
	color: green;
	transition: 200ms ease-in;
}
`;

export const Heading = styled.p`
// font-size: 15px;
// color: #33cc33;
// margin-bottom: 20px;
// font-weight: bold;

@media (max-width: 1000px) {
	// justify-content:center;
// display:flex
margin-left: -20px;
}

`;

export const Heading1 = styled.p`
font-size: 15px;
color: #33cc33;
margin-bottom: 20px;
font-weight: bold;

    img{
        margin-left:50px;
		height: 70px;  
	  }

	@media (max-width: 1000px) {
		
		img{
			margin-left:-5px;
			height: 70px;  
		  }

		h6{
		   margin-left: -100px;
		}
	}
	
`;

export const Heading2 = styled.p`
font-size: 15px;
color: #33cc33;
margin-bottom: 20px;
font-weight: bold;

	@media (max-width: 1000px) {
		margin-left: -30px;

		h6{
			justify-content:center;  
		}
	}	
`;

export const Heading3 = styled.p`
font-size: 15px;
color: #33cc33;
margin-bottom: 20px;
font-weight: bold;

	@media (max-width: 1000px) {
		margin-left: -20px;

		h6{
			justify-content:center; 
			margin-left: -25px; 
		}
	}	
`;

export const Heading4 = styled.p`
font-size: 15px;
color: #33cc33;
margin-bottom: 20px;
font-weight: bold;
display:grid;

	@media (max-width: 1000px) {
		margin-left: -20px;
		display:grid;
	    grid-gap:0px;
		
		h6{
			justify-content:center;  
		}
	}	
`;
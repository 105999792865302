import React from 'react'
import styles from '../../src/Serv2Sec.module.css'
// import Image from "next/image";

function Serv2Sec() {
  return (
    <div className={styles.Container}>
        <div className={styles.serv2sub1}>
            <img className='crop' src='/img/emmi.jpg' alt="money" />   
            <style jsx global>{`
            .crop {
            //   border-radius: 50%;
              object-fit: cover;
              border-radius: 40px 40px 0 0; 
              width:100%;
              
               `}</style>


                <div className={styles.Serv2sub1head}>
                    <div className={styles.Head1}>EMISSION TEST</div>
                        <div className={styles.Head2}>High vehicle emission above the legal limits 
                            pollute the air, harm the environment and human health. 
                            Our inspection identifies vehicles with high emission and 
                            we advise repair based on results.
                        </div>
               </div>
        </div>

        <div className={styles.serv2sub1}>
            <img className='crop' src='/img/under.jpg' width={406} height={384} alt="money" />   
            <style jsx global>{`
            .crop {
            //   border-radius: 50%;
              object-fit: cover;   
            //   border-radius: 0px 0px 0 0;     
            }  
               `}</style>

                <div className={styles.Serv2sub1head}>
                    <div className={styles.Head1}>UNDERNEATH INSPECTION</div>
                        <div className={styles.Head2}>Defects related to braking, suspension, 
                            steering components, chassis and frame of your vehicles are detected 
                            during an underneath inspection, preventing vehicle breakdown with tragic 
                            consequences.
                        </div>
                </div>
        </div>

        <div className={styles.serv2sub1}>
            <img className='crop' src='/img/break.jpg' width={406} height={384} alt="money" />   
            <style jsx global>{`
            .crop {
            //   border-radius: 50%;
              object-fit: cover;       
            }  
               `}</style>

                <div className={styles.Serv2sub1head}>
                    <div className={styles.Head1}>BRAKES EFFICIENCY INSPECTION</div>
                        <div className={styles.Head2}>Inspecting your vehicle braking system 
                            will detect if your brakes are functioning normally or need to be 
                            changed to prevent a potentially fatal accident.<p>`</p>
                        </div>
                </div>
        </div>
      
    </div>
  )
}

export default Serv2Sec

import React from 'react'
import styles from '../../src/Serv1.module.css';

function Serv1() {
  return (
    <div className={styles.servsvg}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#00cba9" fillOpacity="0.3" d="M0,128L120,117.3C240,107,480,85,720,101.3C960,117,1200,171,1320,197.3L1440,224L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path></svg>
    </div>
  )
}

export default Serv1

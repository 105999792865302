import React from 'react'
import styles from "../../src/View.module.css";
// import Link from "next/link"
import "../ind.css"

const view = () => {
  return (
    <div className={styles.mcontainer}>

        <div className=''>
            <div className='absolute'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#00cba9" fillOpacity="0.3" d="M0,224L60,213.3C120,203,240,181,360,181.3C480,181,600,203,720,229.3C840,256,960,288,1080,293.3C1200,299,1320,277,1380,266.7L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>  
            </div>
                  <div className='animate__animated animate__bounceInUp flex flex-row font-extrabold text-xs text-green-900 pt-4 lg:text-3xl lg:-mt-44 justify-center md:pt-32 md:text-xl'>
                      LAGOS COMPUTERISED VEHICLE INSPECTION SERVICE
                  </div> 
        </div>


          
    <div className='px-10 pt-6'>    

    {/* <div className={styles.heady}>
              LAGOS COMPUTERISED VEHICLE INSPECTION SERVICE
              </div>  */}


              
    <div className=' pt-3 md:pt-2 md:px-10 lg:px-24 lg:py-8' >

    LACVIS Nigeria limited was established in 2016,
    to introduce vehicle testing technologies and
    undertake computerized vehicle inspection services 
    in Lagos state. The Lagos Computerized Vehicle 
    Inspection Service, LACVIS, is a special purpose
    vehicle, set up by the Lagos State government and
    other stakeholders to partner with the state’s 
    Vehicle Inspection Service to ensure the safety 
    of the motoring public. LACVIS was set up by a
    strong desire to provide lasting solution to the
    historical antecedents of dangers posed by having
    vehicles that are not road worthy on Lagos State
    roads…<a href="/about" passHref><i className='cursor-pointer'>More..</i></a>  
            </div>
            </div>
    </div>
    
  )
}

export default view
import React from "react";



export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between lg:px-4 py-3 bg-black mb-3">
        <div className="container sm:px-14 lg:px-20 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
              href="/"
            >
              <img style={{ width: 150, height: 45 }} alt='' src="/img/logo.png"/>
            </a>
            <button
              className="text-green-500 cursor-pointer text-xl leading-none px-3 py-1  bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-green-600 hover:text-white"
                  href="/"
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i><span className="ml-2">home</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug  text-green-600 hover:text-white"
                  href="/about"
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i><span className="ml-2">about us</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-green-600 hover:text-white"
                  href="/services"
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Services</span>
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-green-600 hover:text-white"
                  href="/faq"
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i><span className="ml-2">faq</span>
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-green-600 hover:text-white"
                  href="/contact"
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Contact</span>
                </a>
              </li>

              {/* <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-green-600 hover:text-white"
                  href="https://webmail.lacvis.com.ng/"
                >
                  <i className="text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Staff mail</span>
                </a>
              </li> */}

            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
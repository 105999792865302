import React from 'react';
import ServHead from '../../Components/ServHead';
import Serv1 from '../../Components/Serv1';
import Serv2Sec from '../../Components/Serv2Sec';
import Serv3Sec from '../../Components/Serv3Sec';
// import "animate.css"

function Services() {
  return (
    <div> 
      <ServHead/>
      <Serv1/>
      <Serv2Sec/>
      <Serv3Sec/>
    </div>
  )
}

export default Services;
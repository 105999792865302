import React from 'react';
import "../ind.css";
// import styles from "../styles/Viewer.module.css"
// import Image from "next/image";

function Viewerr() {
    return (
        <div className='flex flex-col justify-center py-4 space-y-8 l lg:space-y-0 pb-64 lg:flex-row lg:px-32 lg:space-x-8'>
            <div className='lg:w-1/3 w-full'>
                <img className='rounded-full relative' src='/img/mon.jpg' width={400} height={384} alt="money" />
                <div className='flex absolute text-white text-l ml-16 -mt-64 justify-center lg:-mt-70 lg:text-sm lg:px-16 lg:-ml-16 lg:w-1/3 font-bold'>
                    WE PRIDE IN YOUR SAFETY
                </div>

                <div className='flex absolute lg:w-1/3 w-full text-white justify-center -mt-52 lg:text-sm text-sm lg:px-16 px-6 lg:-ml-12'>
                    We want every journey you make to be a safe one. Frequent 
                    Inspection certifies the roadworthiness of the vehicle, 
                    which indicates that the vehicles are safe to be on the road.
                </div>
            </div> 


            <div className='lg:w-1/3 w-full'>
                <video className='relative rounded-full ' width="400" 
                src="/Videos/vvv.mp4"      
                muted
                autoPlay={"autoplay"}
                preLoad="auto"
                loop></video>


                <div className='flex absolute text-white text-sm ml-24 -mt-64 justify-center lg:-mt-70 lg:text-sm lg:px-16 lg:-ml-12 lg:w-1/3 font-bold'>
                    IT IS FAST AND CHEAP
                </div>

                <div className='flex absolute lg:w-1/3 w-full text-white justify-center -mt-52 lg:text-sm text-sm lg:px-16 px-6 lg:-ml-10'>
                    Inspections can save you money in the long run as small defects can be 
                    identified and rectified with low cost before they become major issues. 
                    Well maintained vehicles run more efficiently, so you get the most out of every drop of fuel.
                </div>    
            </div>


            <div className='lg:w-1/3 w-full'>
                <img className='rounded-full relative' src='/img/money.jpg' width={400} height={384} alt="money" />
                <div className='flex absolute text-white text-l ml-6 -mt-64 justify-center lg:-mt-70 lg:text-sm lg:px-16 lg:-ml-12 lg:w-1/3 font-bold'>
                    WE WANT A TOXIC-FREE ENVIRONMENT
                </div>

                <div className='flex absolute lg:w-1/3 w-full text-white justify-center -mt-52 lg:text-sm text-sm lg:px-16 px-6 lg:-ml-12'>
                Vehicles emit smoke and dangerous gases that pollute the environment. 
                Certifying that vehicles that emit dangerous gases and smoke are off 
                the road and the environment is safe for road users.
                </div> </div>     
            
           

        </div>  
        
          )
        }
        

export default Viewerr
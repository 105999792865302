// import styles from "../styles/Disply.module.css";
// import Image from "next/image";
// import Link from "next/link"



const Disply = () => {
    return (
      <div> 

  <div className="flex flex-row bg-green-700 text-white py-2 justify-center -mb-26 lg:hidden -mt-14">
    <a href="/contact" passHref>
        FIND A CENTRE CLOSE TO YOU
    </a>   
  </div>

        </div>

    );
};
export default Disply;
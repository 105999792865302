import React from 'react'

function Career() {
  return (
    <div className=' -mt-5 '>


    <img src="/img/terms.jpg" alt="" width={1550} height={500}/>
     
     <div className='lg:px-40 pt-5 text-3xl text-green-900 font-bold flex flex-col text-center'>
      <h1>CAREER</h1>
     </div>
   
    <div className='flex justify-center text-xl font-bold px-4 mb-2 text-center'>
        We believe in attracting and retaining the best people, who can make a positive contribution to our company’s future
    </div>
    <div className='flex justify-center px-4 mb-8'>
        We look for talented people with drive or relevant experience  and the right attitude to join the team.
    </div>

    <div className='flex justify-center text-base pb-10'>
    Sorry, we're not hiring at the moment...
    </div>
     
    </div>
  )
}

export default Career

import React from 'react'
import styles from "../../src/Viewer1.module.css";

const viewer1 = () => {
  return (
    <div className=" lg:-mt-0">




    <div className={styles.Wrap}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#00cba9"
          fillOpacity="0.3"
          d="M0,224L80,186.7C160,149,320,75,480,85.3C640,96,800,192,960,197.3C1120,203,1280,117,1360,74.7L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
  )
}

export default viewer1
import React from 'react'
import styles from "../../src/AbtHead.module.css"
// import Image from "next/image";


function AbtHead() {
  return (
    <div className={styles.container}>
        <div className={styles.ImgHead}>
             <img src="/img/bout.jpg" alt=""/>
        </div>
    </div>
  )
}

export default AbtHead
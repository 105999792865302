import React from 'react';
import Marquee from "react-fast-marquee";
import '../ind.css';
import Heww from './Heww';



export default function App(){
  return (
    <div className='mt-32 '>
      
      <Marquee
    behavior='scroll'
    scrollamount='20'
    width='50%'
    direction='left'
    height='150px'
    speed={100}
    
    >
    {/* <p className='mt-24 font-extrabold text-5xl text-green-700'>-  Over 2 million vehicles inspected - - -
     Over 2 million customers - - - 
     100% Happy Clients - - - 34 Inspection Centres - - - Over 40 Inspection Lanes</p> */}
    </Marquee>

   

        <div class="lg:grid grid-cols-2 gap-4 ">
            
            <div className='flex flex-col lg:pt-10 font-semibold text-center'>
               <p className='flex flex-row justify-center pb-4'> <img src="/img/vislacvis.png" width={300} height={400} alt=""/> </p>
            
            <div className='flex flex-col justify-center text-xl'>
                <p>OVER 2 MILLION VEHICLE INSPECTED</p>
                <p>34 INSPECTION CENTRES</p>
                <p>OVER 40 INSPECTION LANE</p>
                <p>100% HAPPY CLIENT</p>
                <p>OVER 2 MILLION CUSTOMERS</p>
            </div>
            </div>

            <div className='mt-28 mr-'>
            <Heww/> 
            </div>
</div>
        
    </div>

   


  );
}


import React from 'react'
import styles from "../../src/ServHead.module.css"

function ServHead() {
  return (
    <div className={styles.container}>
      
        <div className={styles.ImgHead}>
         <img src="/img/SERVICES.jpg" alt="" />
        </div>

        <div className={styles.serv0}>
        Keeping your Vehicle in peak operating condition is the best way 
        to ensure your safety on the road as well as reducing your overall 
        monitoring costs in Lagos and its environs. Regular inspections can 
        help you identify potential hazards and save you money by preventing 
        additional damage before it happens. It is for these reasons that the 
        Lagos Computerized Vehicle Inspection Services. LACVIS, a recongnized 
        Nigerian vehicle-inspection organization, was set up to make Lagos roads 
        safer and healthier for habitant
        </div>
    </div>
  )
}

export default ServHead

import React from "react";
// import Image from "next/image";
import styles from "../../src/Secview.module.css";





const Secview = () => {
 
  return (
    <div className={styles.Container} >
    
             <div className={styles.secc}>
              <i > UNRAVEL THE DEFECTS OF YOUR <br/>VEHICLE</i>
         
              <b></b>
              </div>

           <img src="/img/hview1.png" alt="" width={1550} height={750}/> 
      
     </div>
        
  )
}

export default Secview;
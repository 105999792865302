import React from 'react'
import AbtHead from "../../Components/AbtHead";
import AbtMid from '../../Components/AbtMid';
import Ourpips1 from '../../Components/Ourpips1';
// import "animate.css"

function About() {
  return (
    <div className="">
      

      <AbtHead />
      <AbtMid />
      <Ourpips1/>
   
    
    </div>
  )
}

export default About

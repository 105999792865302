import React from 'react'
// import Head from 'next/head';
// import Image from "next/image";
// import "animate.css"

function Condition() {
  return (
    <div className='bg-gray-300 -mt-5'>


        <img src="/img/terms.jpg" alt=""/>
         
         <div className='lg:px-40 pt-5 text-3xl text-green-900 font-bold flex flex-col text-center pb-5'>
          <h1>TERMS AND CONDITION</h1>
         </div>

         
         <div className='lg:px-40 text-justify pb-5'>
            {/* <p className='text-xl font-bold pt-11 '>COLLECTION AND USE OF INFORMATION</p> */}

              <p className='lg:px-0 text-lg px-5 lg:text-base'>
              While every care has been taken to ensure the accuracy of the information, statements, text, articles, 
              data, images, screens, and other materials contained and appearing in this website, we make no representations, 
              guarantees or warranties as to the accuracy, reliability, quality, truth, suitability and completeness of the said 
              materials. We reserve the right to add, delete or make any changes to the materials without prior notice.

              We cannot accept any liability for inaccuracies or omissions in this website. We further disclaim all liabilities 
              whatsoever for any direct, indirect, special or consequential loss or damages howsoever resulting directly or 
              indirectly from the access to or the use of this website and the reliance on the materials contained herein. 
              Any decisions made by you based on the materials are your sole responsibility.
              </p>
            
             <p className='pt-5 lg:px-0 text-lg px-5 lg:text-base'>We do not warrant that the function or operation of this website will be uninterrupted or error-free, 
             that defects will be corrected, or that this website or the server that makes it available will be free of viruses or other 
             harmful elements.

             LACVIS Nig. Ltd. is the owner or licensee of all copyrights in and to the content on this website, including without limitation, 
             (i) all materials, documentation, text, data, graphics, graphs, charts, buttons, photographs, videos, typefaces, music, sounds, 
             HTML code, and interfaces contained on this website; and (ii) the design, selection and arrangement of this website.</p>

             <p className='pt-5 lg:px-0 text-lg px-5 lg:text-base'>All trademarks, service marks, trade names, logos, and other designations are the sole property of LACVIS Nig.
              Ltd. Nothing contained on this website should be construed as granting any license or right to use any such marks without the 
              written permission of LACVIS Nig. Ltd.</p>

            
            <p className='text-xl font-bold pt-11 flex flex-col text-center lg:text-left'>VEHICLE INSPECTOR DISCLAIMER</p>
            <p className='pt-5 lg:px-0 text-lg px-5 lg:text-base'>The LACVIS Inspection Report (VIR) reflects the condition of the vehicle at the time of the inspection. 
            That condition is subject to change between the time the inspection is conducted to the time you see the vehicle. 
            These changes may result from physical damage to the vehicle, owner modifications, inferior repairs, wear and tear etc. 
            LACVIS is not liable for any such changes that occur after its vehicle examiners have completed their inspections.</p>
          </div>

    </div>
  )
}

export default Condition
